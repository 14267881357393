import React from 'react';
import { SmartTrack as Track } from 'ha/components/track/SmartTrack';
import { useIntl } from 'ha/i18n';
import { usePage } from 'ha/modules/Page/contexts/PageContext';
import {
  ExtendProps,
  ExtendedComponent,
  ExtendedExternalLinkProps,
} from '../ExtendedComponent/types';

const OnboardingHelpTrack = ({
  children,
}: React.PropsWithChildren<unknown>) => {
  const { category } = usePage();

  return (
    <Track name="Clicked FAQ in NavBar" pageCategory={category}>
      {children}
    </Track>
  );
};

const OnboardingHelpLinkLabel = () => {
  const { T } = useIntl();

  return T('nav.header.help');
};

const OnboardingHelpLink = React.forwardRef(
  <CustomComponent extends ExtendedComponent<ExtendedExternalLinkProps>>(
    { component, children, ...rest }: ExtendProps<CustomComponent>,
    ref: React.ForwardedRef<React.ComponentRef<CustomComponent>>,
  ) => {
    const { urlResolver } = useIntl();

    const Component = component as ExtendedComponent<ExtendedExternalLinkProps>;

    return (
      <Component
        ref={ref}
        {...rest}
        component="a"
        href={urlResolver.getAnswersUrl()}
      >
        {children ?? <OnboardingHelpLinkLabel />}
      </Component>
    );
  },
);

export const OnboardingHelp = {
  Track: OnboardingHelpTrack,
  Link: OnboardingHelpLink,
  Label: OnboardingHelpLinkLabel,
};
