import React from 'react';
import { useMediaQuery, useTheme } from '@mui/material';

import { Img } from 'ha/ui/Img';
import housingAnywhereLogo from 'ha/img-src/logos/housing-anywhere-logo.svg';
import housingAnywhereLogoSecondary from 'ha/img-src/logos/housing-anywhere-logo-grey.svg';
import housingAnywhereIconLogo from 'ha/img-src/logos/housing-anywhere-icon.svg';
import housingAnywhereIconLogoSecondary from 'ha/img-src/logos/housing-anywhere-icon-grey.svg';
import { makeStyles } from 'tss-react/mui';
import { DynamicIslandContext } from '../HeaderDynamicIsland/context';

export interface HousingAnywhereLogoProps {
  size: 'full' | 'compact';
  color: 'primary' | 'secondary';
  className?: string;
}

const useStyles = makeStyles()(() => ({
  icon: {
    fontSize: '32px',
  },
}));

export const HousingAnywhereLogo = ({
  size,
  color,
  className,
}: HousingAnywhereLogoProps) => {
  const { classes, cx } = useStyles();

  if (size === 'compact') {
    return (
      <Img
        src={
          color === 'primary'
            ? housingAnywhereIconLogo
            : housingAnywhereIconLogoSecondary
        }
        alt="HousingAnywhere"
        className={cx(classes.icon, className)}
      />
    );
  }

  if (size === 'full') {
    return (
      <Img
        src={
          color === 'primary'
            ? housingAnywhereLogo
            : housingAnywhereLogoSecondary
        }
        alt="HousingAnywhere"
        className={className}
      />
    );
  }

  return null;
};

export type NavigationLogoProps = Omit<
  HousingAnywhereLogoProps,
  'size' | 'color'
> & {
  size?: HousingAnywhereLogoProps['size'];
};

export const NavigationLogo = ({ size, ...rest }: NavigationLogoProps) => {
  const theme = useTheme();
  const isLargerThanSm = useMediaQuery(theme.breakpoints.up('sm'));

  const { isDynamicIslandShown } = DynamicIslandContext.useContext();

  if (size) {
    return <HousingAnywhereLogo size={size} color="primary" {...rest} />;
  }

  if (!isLargerThanSm && isDynamicIslandShown) {
    return <HousingAnywhereLogo size="compact" color="primary" {...rest} />;
  }

  return <HousingAnywhereLogo size="full" color="primary" {...rest} />;
};
