import React from 'react';

import { Helmet } from 'react-helmet-async';

import favicon from 'ha/img-src/favicon/favicon.png';
import favicon57 from 'ha/img-src/favicon/favicon-57.png';
import favicon72 from 'ha/img-src/favicon/favicon-72.png';
import favicon114 from 'ha/img-src/favicon/favicon-114.png';
import favicon120 from 'ha/img-src/favicon/favicon-120.png';
import favicon144 from 'ha/img-src/favicon/favicon-144.png';
import favicon152 from 'ha/img-src/favicon/favicon-152.png';

export const Favicons: React.FC = () => (
  <Helmet>
    <link rel="shortcut icon" sizes="32x32" href={favicon} />
    <link rel="apple-touch-icon" sizes="57x57" href={favicon57} />
    {/* eslint-disable-next-line react/no-invalid-html-attribute */}
    <link rel="apple-touch-icon-precomposed" sizes="57x57" href={favicon57} />
    <link rel="apple-touch-icon" sizes="72x72" href={favicon72} />
    <link rel="apple-touch-icon" sizes="114x114" href={favicon114} />
    <link rel="apple-touch-icon" sizes="120x120" href={favicon120} />
    <link rel="apple-touch-icon" sizes="144x144" href={favicon144} />
    <link rel="apple-touch-icon" sizes="152x152" href={favicon152} />
  </Helmet>
);
