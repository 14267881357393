import React from 'react';

import { createRequiredContext } from 'ha/core/RequiredContext';

export interface PageLayoutState {
  hasStickyNav?: boolean;
  hideCTA?: boolean;
  hasSidebar?: boolean;
  searchBarElement?: React.ReactNode;
  showDetailsPanel: () => void;
  hideDetailsPanel: () => void;
}

export const PageLayoutContext =
  createRequiredContext<PageLayoutState>('PageLayoutContext');
