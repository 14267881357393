import React from 'react';

import { useSelector } from 'react-redux';

import { getAnonymousId } from '@hbf/analytics';

import { reportError } from 'ha/helpers/bugReporter/reportError';
import { useConfig } from 'ha/helpers/config';
import {
  getUserId,
  getUserIdAlias,
  getUserTraits,
  getAnalyticsUserData,
} from 'ha/modules/AuthLogic/selectors';
import { useServices } from 'ha/services';

import { getUserIdentifier } from 'ha/modules/Experiments/selectors';

import {
  intercomAPI,
  transformUserData,
  loadIntercomScript,
  createIntercomPlaceholder,
} from '../utils';
import { UserData } from '../utils/transformUserData';

interface Props {
  preloadScript?: boolean;
}

let intercomScriptP: Promise<void> | undefined;

const useIntercom = ({ preloadScript }: Props = {}) => {
  const userId = useSelector(getUserId);
  const aliasId = useSelector(getUserIdAlias);
  const userTraits = useSelector(getUserTraits);
  const userDataAnalytics = useSelector(getAnalyticsUserData);
  const userIdentifier = useSelector(getUserIdentifier);
  const { analytics } = useServices();

  const {
    intercom: { appID },
    analytics: { enabled: analyticsEnabled },
  } = useConfig();

  const updateUserInfo = React.useCallback(async () => {
    let intercomUserId = userId ? aliasId : userIdentifier;

    // get anonymous id from analytics so that userId sent here matches the one sent by rudderstack cloud mode
    if (analyticsEnabled && !userId) {
      const anonymousId = await new Promise<string>(resolve => {
        analytics.onReady(() => {
          resolve(getAnonymousId() || '');
        });
      });
      if (anonymousId) {
        intercomUserId = anonymousId;
      }
    }

    intercomAPI.update(
      transformUserData({
        // TODO: use user id from rudderstack first
        userId: intercomUserId,
        ...userTraits,
        ...userDataAnalytics,
      } as UserData),
    );
  }, [
    aliasId,
    userDataAnalytics,
    userId,
    userIdentifier,
    userTraits,
    analyticsEnabled,
    analytics,
  ]);

  const loadIntercom = React.useCallback(() => {
    if (!window.intercomSettings && appID) {
      window.intercomSettings = {
        app_id: appID,
      };
    }
    if (!window.Intercom) {
      createIntercomPlaceholder();
    }
    if (intercomScriptP) {
      return intercomScriptP;
    }
    intercomScriptP = new Promise<void>(resolve => {
      loadIntercomScript(appID, resolve, error => {
        // reset on fail so the next click tries to re-fetch the script
        reportError('Load Intercom script', {
          metaData: error,
          severity: 'error',
        });
      });
    }).then(() => updateUserInfo());
    return intercomScriptP;
  }, [appID, updateUserInfo]);

  React.useEffect(() => {
    if (!preloadScript) {
      return;
    }

    loadIntercom();
  }, [loadIntercom, preloadScript]);

  const show = React.useCallback(() => {
    loadIntercom().then(() => {
      intercomAPI.show();
    });
  }, [loadIntercom]);

  const showLauncher = React.useCallback(() => {
    loadIntercom().then(() => {
      intercomAPI.showLauncher();
    });
  }, [loadIntercom]);

  return { show, showLauncher };
};

export { useIntercom };
