import React from 'react';
import { createRequiredContext } from 'ha/core/RequiredContext';
import {
  UseFreeBookings,
  useFreeBookings,
} from 'ha/helpers/hooks/useFreeBookings';

export const FreeBookingsCounterContext =
  createRequiredContext<UseFreeBookings>('FreeBookingsCounterContext');

export const FreeBookingsCounterProvider: React.FC<
  React.PropsWithChildren<unknown>
> = ({ children }) => {
  const freeBookingsData = useFreeBookings();

  return (
    <FreeBookingsCounterContext.Provider value={freeBookingsData}>
      {children}
    </FreeBookingsCounterContext.Provider>
  );
};
