import React from 'react';

import { useSelector } from 'react-redux';
import { Portal, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/system';
import { getIsAdvertiser, getIsAuthenticated } from '../AuthLogic/selectors';
import { AdvertiserNavigationBottomBarItems } from './components/AdvertiserNavigationBottomBarItems/AdvertiserNavigationBottomBarItems';
import { TenantNavigationBottomBarItems } from './components/TenantNavigationBottomBarItems/TenantNavigationBottomBarItems';
import { NavigationBottomBar } from './components/NavigationBottomBar/NavigationBottomBar';

const NavigationBottomNavigationBar = () => {
  const isAdvertiser = useSelector(getIsAdvertiser);
  const isAuthenticated = useSelector(getIsAuthenticated);

  if (!isAuthenticated) return null;

  if (isAdvertiser) {
    return (
      <NavigationBottomBar.Content>
        <AdvertiserNavigationBottomBarItems />
      </NavigationBottomBar.Content>
    );
  }

  return (
    <NavigationBottomBar.Content>
      <TenantNavigationBottomBarItems />
    </NavigationBottomBar.Content>
  );
};

export const NavigationBottom = ({
  children,
}: React.PropsWithChildren<unknown>) => {
  const theme = useTheme();

  const isLargerThanMd = useMediaQuery(theme.breakpoints.up('md'));

  if (isLargerThanMd) return null;

  return (
    <Portal>
      <NavigationBottomBar.Container>{children}</NavigationBottomBar.Container>
    </Portal>
  );
};

NavigationBottom.NavigationBar = NavigationBottomNavigationBar;
