import React from 'react';

import { useSelector } from 'react-redux';

import { getInboxUnreadCount } from 'ha/modules/InboxCounter/selectors';
import { useKycInfo } from 'ha/modules/Kyc';
import { determineKYCState } from 'ha/modules/Kyc/utils/determineKYCState';

import { NavBarSide } from '../components/NavBarSide';

export const NavBarSideContainer: React.FC = () => {
  const unreadMessageCount = useSelector(getInboxUnreadCount);

  const {
    kycInfo,
    userKYCDetails: { entities },
  } = useKycInfo();

  const kycStatus = React.useMemo(() => {
    if (kycInfo.fetchStatus !== 'success') return undefined;

    return determineKYCState(kycInfo, entities[0]?.kycStatus);
  }, [kycInfo, entities]);

  return (
    <NavBarSide unreadMessageCount={unreadMessageCount} kycStatus={kycStatus} />
  );
};

// eslint-disable-next-line import/no-default-export
export default NavBarSideContainer;
