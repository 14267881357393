import React from 'react';

import { Dropdown, DropdownProps } from '@hbf/dsl/core';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(theme => ({
  menu: {
    maxWidth: '350px',
  },
  block: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(5),
    gap: theme.spacing(5),
  },
}));

const NavigationDropdownMenuBlock = ({
  children,
}: React.PropsWithChildren<unknown>) => {
  const { classes } = useStyles();

  return <div className={classes.block}>{children}</div>;
};

export const NavigationDropdownMenu = ({
  children,
  ...rest
}: React.PropsWithChildren<DropdownProps>) => {
  const { classes } = useStyles();

  return (
    <Dropdown {...rest}>
      <div className={classes.menu}>{children}</div>
    </Dropdown>
  );
};

NavigationDropdownMenu.Block = NavigationDropdownMenuBlock;
