import { KycStates, UserKYCStatus, KycInfo } from '@ha/api/v2/types/Kyc';

export const determineKYCState = (
  kycInfo: KycInfo,
  KYCStatus?: UserKYCStatus | string,
): KycStates => {
  if (kycInfo.requirements?.hasExpiringRequirements) return KycStates.EXPIRING;

  if (kycInfo.kycStarted && !kycInfo.kycDone) {
    if (KYCStatus === UserKYCStatus.CHANGES_REQUESTED) {
      return KycStates.MISSING_DATA;
    }
    // No banner for pending state currently
    if (KYCStatus === UserKYCStatus.PENDING) return KycStates.PENDING;

    return KycStates.UNKNOWN;
  }

  if (!kycInfo.kycStarted && !kycInfo.kycDone) {
    return KycStates.NOT_VERIFIED;
  }

  if (kycInfo.kycStarted && kycInfo.kycDone) {
    return KycStates.VERIFIED;
  }

  // add more KYC states in the future
  return KycStates.UNKNOWN;
};
