import React from 'react';

import { useNavigation, useRevalidator } from 'react-router-dom';

import { Environment } from 'ha/config';

import LinearProgress from '@mui/material/LinearProgress';

import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(() => ({
  root: {
    position: 'fixed',
    width: '100%',
    // 1300 is the z-index for the Google Map on search page
    // and we want this progress bar to be above that.
    zIndex: 1301,
  },
}));

export const ProgressBar: React.FC<{ active: boolean }> = ({ active }) => {
  const navigation = useNavigation();
  const revalidator = useRevalidator();

  const { classes } = useStyles();

  const isLoading = active && navigation.state === 'loading';

  if (isLoading) {
    return (
      <div className={classes.root} data-test-locator="PageProgressBar">
        <LinearProgress />
      </div>
    );
  }

  const isReactRouterLoading =
    navigation.state === 'loading' ||
    navigation.state === 'submitting' ||
    revalidator.state === 'loading';

  // used to indicate react router loading states that are not explicitly tracked with `Page`' `hasProgressBar` .. only in development
  if (
    process.env.NODE_ENV === Environment.DEVELOPMENT &&
    isReactRouterLoading
  ) {
    return (
      <div className={classes.root} data-test-locator="PageProgressBar">
        <LinearProgress color="warning" />
      </div>
    );
  }

  return null;
};
