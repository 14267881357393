import React from 'react';
import { makeStyles } from 'tss-react/mui';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';

import { useIntl } from 'ha/i18n';
import { /* Link, */ Typography, LinearProgress, units } from '@hbf/dsl/legacy';
import { UseFreeBookings } from 'ha/helpers/hooks/useFreeBookings';

const useStyles = makeStyles()(theme => ({
  freeUsageActive: {
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
  },
  progressBar: {
    width: '60%',
    marginTop: theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.grey['200'],
    '.MuiLinearProgress-bar1Determinate': {
      backgroundColor: theme.palette.grey['600'],
    },
  },
  freeUsageExpired: {
    position: 'relative',
    border: units.border(1, 'solid', theme.palette.grey['200']),
    borderRadius: theme.spacing(1),
    padding: theme.spacing(1.5),
    marginTop: theme.spacing(1.25),
  },
  deductionText: {
    wordBreak: 'break-word',
    whiteSpace: 'normal',
    margin: units.trbl(0.5, 0, 0.5, 0),
  },
  fontSmall: {
    fontSize: units.pxToRem(12),
  },
  closeIconButton: {
    position: 'absolute',
    backgroundColor: theme.palette.common.white,
    border: units.border(1, 'solid', theme.palette.grey['200']),
    width: theme.spacing(3),
    height: theme.spacing(3),
    borderRadius: '50%', // circular shape
    top: theme.spacing(-1.25),
    right: theme.spacing(-1.25),
    display: ' flex',
    justifyContent: ' center',
    alignItems: ' center',
    '.MuiSvgIcon-root': {
      fontSize: units.rem(0.7),
    },
  },
  primaryText: {
    color: theme.palette.primary.main,
  },
  secondaryText: {
    color: theme.palette.grey['600'],
  },
  link: {
    textDecoration: 'underline !important',
    ':hover': {
      textDecoration: 'underline',
    },
  },
}));

export const FreeBookingsCounter: React.FC<{
  freeBookingsData: UseFreeBookings;
}> = ({ freeBookingsData }) => {
  const { T } = useIntl();
  const { classes, cx } = useStyles();

  if (!freeBookingsData) return null;

  if (
    freeBookingsData.isFreeBookingsUsedUp &&
    !freeBookingsData.showExpiredBlock
  ) {
    return null;
  }

  if (
    freeBookingsData.isFreeBookingsUsedUp &&
    freeBookingsData.showExpiredBlock
  ) {
    return (
      <div
        className={classes.freeUsageExpired}
        data-test-locator="NavBarSide.FreeBookingsCounter.Expired"
      >
        <Typography.Caption fontWeight="bold">
          {T(
            'free_plan.free_bookings_used_%d_long',
            freeBookingsData.freeBookingsUsage,
            freeBookingsData.maxFreeBookings,
          )}
        </Typography.Caption>
        <Typography.Caption className={classes.fontSmall}>
          {T(
            'free_plan.limit_renews_date_%s',
            freeBookingsData.nextRenewalDateFormatted,
          )}
        </Typography.Caption>
        <Typography.Caption
          className={cx(classes.deductionText, classes.fontSmall)}
        >
          {T('free_plan.limit_over_pay_commission')}
        </Typography.Caption>
        {/*  <Link to="#" className={cx(classes.link, classes.primaryText)}>
          <Typography.Caption className={classes.primaryText}>
            {T('cta.learn_more')}
          </Typography.Caption>
        </Link> */}
        <IconButton
          className={classes.closeIconButton}
          onClick={freeBookingsData.handleCloseExpiredUsageCard}
          data-test-locator="NavBarSide.FreeBookingsCounter.Expired.CloseCTA"
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      </div>
    );
  }

  return (
    <div
      className={classes.freeUsageActive}
      data-test-locator="NavBarSide.FreeBookingsCounter.Active"
    >
      <Typography.Caption fontWeight="bold">
        {T(
          'free_plan.free_bookings_used_%d_long',
          freeBookingsData.freeBookingsUsage,
          freeBookingsData.maxFreeBookings,
        )}
      </Typography.Caption>
      <Typography.Caption className={classes.secondaryText}>
        {T(
          'free_plan.limit_renews_date_%s',
          freeBookingsData.nextRenewalDateFormatted,
        )}
      </Typography.Caption>
      {/* <Link to="#" className={cx(classes.secondaryText, classes.link)}>
        <Typography.Caption className={classes.secondaryText}>
          {T('cta.learn_more')}
        </Typography.Caption>
      </Link> */}
      <LinearProgress
        variant="determinate"
        className={classes.progressBar}
        value={freeBookingsData.freeBookingsUsagePercentage}
      />
    </div>
  );
};
