import React from 'react';

import {
  Chat,
  Favorite,
  HandCoins,
  Notifications,
} from '@hbf/icons/brand-regular';
import { useIntl } from 'ha/i18n';
import { Badge } from '@hbf/dsl/core';
import { useFavoriteListings } from 'ha/modules/FavoriteListing/hooks/useFavoriteListings';
import { useInboxCounters } from 'ha/modules/InboxCounter/containers/InboxCounter';
import { NavigationBottomBar } from '../NavigationBottomBar/NavigationBottomBar';

export const TenantNavigationBottomBarItems = () => {
  const { T, urlResolver } = useIntl();

  const { inboxUnread } = useInboxCounters();
  const { favoriteListingsCount } = useFavoriteListings();

  return (
    <React.Fragment>
      <NavigationBottomBar.Item.Link href={urlResolver.getMyInboxUrl()}>
        <NavigationBottomBar.Item
          slots={{
            icon: (
              <NavigationBottomBar.Item.Icon>
                <Badge badgeContent={inboxUnread}>
                  <Chat />
                </Badge>
              </NavigationBottomBar.Item.Icon>
            ),
          }}
        >
          {T('nav.header.inbox')}
        </NavigationBottomBar.Item>
      </NavigationBottomBar.Item.Link>
      <NavigationBottomBar.Item.Link href={urlResolver.getFavoritesUrl()}>
        <NavigationBottomBar.Item
          slots={{
            icon: (
              <NavigationBottomBar.Item.Icon>
                <Badge badgeContent={favoriteListingsCount}>
                  <Favorite />
                </Badge>
              </NavigationBottomBar.Item.Icon>
            ),
          }}
        >
          {T('nav.header.fav')}
        </NavigationBottomBar.Item>
      </NavigationBottomBar.Item.Link>
      <NavigationBottomBar.Item.Link href={urlResolver.getSearchAlertsUrl()}>
        <NavigationBottomBar.Item
          slots={{
            icon: (
              <NavigationBottomBar.Item.Icon>
                <Notifications />
              </NavigationBottomBar.Item.Icon>
            ),
          }}
        >
          {T('nav.header.alerts')}
        </NavigationBottomBar.Item>
      </NavigationBottomBar.Item.Link>
      <NavigationBottomBar.Item.Link href={urlResolver.getPaymentMethodsUrl()}>
        <NavigationBottomBar.Item
          slots={{
            icon: (
              <NavigationBottomBar.Item.Icon>
                <HandCoins />
              </NavigationBottomBar.Item.Icon>
            ),
          }}
        >
          {T('nav.header.payments')}
        </NavigationBottomBar.Item>
      </NavigationBottomBar.Item.Link>
    </React.Fragment>
  );
};
