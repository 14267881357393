import { FontInfo } from '../types';
import { generateFontFaceStyle } from './generateFontFaceStyle';
import { generateShorthandFontProperty } from './generateShorthandFontProperty';

const generateLoadingScript = (activeFonts: FontInfo[]) => {
  const fontInfo = activeFonts
    .flatMap(font =>
      font.stage2Files.map(
        file =>
          `{name: "${
            font.fontName
          }", fontProperty: "${generateShorthandFontProperty(
            font.fontName,
            file,
          )}", src: "${file.src}", options: ${JSON.stringify(file.options)}}`,
      ),
    )
    .join(',');

  const fallbackStyles = activeFonts
    .flatMap(font =>
      font.stage2Files.map(file => generateFontFaceStyle(font.fontName, file)),
    )
    .join(' ');

  return String.raw`
(function () {
  if( "fonts" in document ) {
    var fontInfo = [${fontInfo}].filter(function(font){return !document.fonts.check(font.fontProperty)});
    var activeFonts = fontInfo.map(function(font){return new FontFace(font.name, font.src, font.options)});
    Promise.all(activeFonts.map(function(font){return font.load()})).then(function(fonts) {
        fonts.forEach(function(font) {
            document.fonts.add(font);
        });
    });
  }
  if(!("fonts" in document) && "head" in document) {
    var style = document.createElement("style");
    style.innerHTML = "${fallbackStyles}";
    document.head.appendChild(style);
}
})();
`;
};

export { generateLoadingScript };
