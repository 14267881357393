import { FontFile } from '../types';

const generateShorthandFontProperty = (fontName: string, file: FontFile) => {
  const values = [
    file.options.style,
    file.options.variant,
    file.options.weight,
    '16px',
    fontName,
  ].filter(Boolean);

  return values.join(' ');
};

export { generateShorthandFontProperty };
