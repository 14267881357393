import React from 'react';

import { useIntl } from 'ha/i18n';

import { useIntercom } from 'ha/modules/Intercom';

import {
  ExtendProps,
  ExtendedClickableProps,
  ExtendedComponent,
} from '../ExtendedComponent/types';

const OnboardingCustomerSupportChatLabel = () => {
  const { T } = useIntl();

  return T('nav.header.chat_support');
};

const OnboardingCustomerSupportChatLink = React.forwardRef(
  <CustomComponent extends ExtendedComponent<ExtendedClickableProps>>(
    { component, children, ...rest }: ExtendProps<CustomComponent>,
    ref: React.ForwardedRef<React.ComponentRef<CustomComponent>>,
  ) => {
    const Component = component as ExtendedComponent<ExtendedClickableProps>;
    const { show } = useIntercom();

    return (
      <Component ref={ref} {...rest} onClick={() => show()}>
        {children ?? <OnboardingCustomerSupportChatLabel />}
      </Component>
    );
  },
);

export const OnboardingCustomerSupportChat = {
  Link: OnboardingCustomerSupportChatLink,
  Label: OnboardingCustomerSupportChatLabel,
};
