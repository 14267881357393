import React from 'react';
import cx from 'classnames';

import NoPhotoImg from 'ha/img-src/static/nophotos.png';

export interface Props {
  // the src of the image
  src: string;
  // default src for the image.
  // This is used in case the image src fails to load with a 404
  defaultSrc?: string;
  srcSet?: string;
  role?: string;
  onLoad?: (img: HTMLImageElement) => void;
  onError?: (img: HTMLImageElement) => void;
  alt?: string;
  className?: string;
  sizes?: string;
  isThumbnail?: boolean;
  isResponsive?: boolean;
  circle?: boolean;
  rounded?: boolean;
  style?: React.CSSProperties;
  placeholder?: React.ReactNode;
  itemProp?: string;
  timeoutMs?: number;
  dataTestLocator?: string;
  loading?: 'lazy' | 'eager';
}

interface DefaultProps {
  defaultSrc: string;
  isThumbnail: boolean;
  isResponsive: boolean;
  circle: boolean;
  rounded: boolean;
  placeholder: React.ReactNode;
  timeoutMs: number;
}

interface State {
  loaded: boolean;
  fallback: boolean;
}

class ImgInternal extends React.Component<Props & DefaultProps, State> {
  static propTypes = {
    // the alt tag to give to the image
    alt: (props: Props, propName: string) => {
      const altValue = props[propName];
      const roleValue = props.role;

      if (typeof altValue === 'string' && altValue !== '') {
        return undefined;
      }

      if (roleValue === 'presentation') {
        return undefined;
      }

      return new Error(
        'You are creating an img with neither alt prop neither role set to presentation.\n' +
          'To help SEO on your page, make sure to either give an alt text or to set the ' +
          "role tag of the img to 'presentation'",
      );
    },
  };

  static defaultProps: DefaultProps = {
    defaultSrc: NoPhotoImg,
    isThumbnail: false,
    isResponsive: false,
    circle: false,
    rounded: false,
    placeholder: null,
    timeoutMs: 60000,
  };

  imageRef = React.createRef<HTMLImageElement>();

  state: State = {
    loaded: false,
    fallback: false,
  };

  timer: number | undefined = undefined;

  componentDidMount() {
    // Image is already loaded when mounting (SSR)
    if (
      this.imageRef.current &&
      this.imageRef.current.complete &&
      !this.state.loaded
    ) {
      // Loaded with error
      if (this.imageRef.current.naturalWidth === 0) {
        this.handlePictureError();
      } else {
        this.handlePictureLoad();
      }

      return;
    }

    this.timer = window.setTimeout(this.handleFallback, this.props.timeoutMs);
  }

  handleFallback = () => {
    this.timer = undefined;

    if (this.state.loaded) {
      return;
    }

    this.setState({ fallback: true });
  };

  handlePictureLoad = () => {
    if (!this.imageRef.current) {
      return;
    }

    if (this.timer) {
      clearTimeout(this.timer);
      this.timer = undefined;
    }

    this.setState({ loaded: true });

    if (this.props.onLoad) {
      this.props.onLoad(this.imageRef.current);
    }
  };

  handlePictureError = () => {
    if (this.props.onError) {
      this.props.onError(this.imageRef.current!);
    }

    this.setState({
      fallback: true,
    });
  };

  componentWillUnmount() {
    if (this.timer) {
      clearTimeout(this.timer);
      this.timer = undefined;
    }
  }

  render() {
    const {
      alt,
      role,
      src,
      srcSet,
      className,
      isResponsive,
      isThumbnail,
      circle,
      rounded,
      sizes,
      style,
      placeholder,
      itemProp,
      dataTestLocator,
      loading,
    } = this.props;
    const { fallback, loaded } = this.state;

    return (
      <React.Fragment>
        <img
          className={cx(
            {
              'img-thumbnail': isThumbnail,
              'img-responsive': isResponsive,
              'img-circle': circle,
              'img-rounded': rounded,
            },
            className,
          )}
          ref={this.imageRef}
          role={role}
          alt={alt}
          src={fallback ? this.props.defaultSrc : src}
          // ignore srcSet in case of fallback
          srcSet={fallback ? undefined : srcSet}
          onLoad={this.handlePictureLoad}
          onError={this.handlePictureError}
          style={style}
          sizes={sizes}
          loading={loading}
          itemProp={itemProp}
          data-test-locator={dataTestLocator}
        />
        {!loaded && placeholder}
      </React.Fragment>
    );
  }
}

const Img = ImgInternal as React.ComponentClass<Props>;
export { Img };
