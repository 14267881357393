import React from 'react';

import { useLocation } from 'react-router-dom';

import { makeStyles } from 'tss-react/mui';

import { KycStates } from '@ha/api/v2/types/Kyc';
import {
  MenuList,
  ListItemText,
  ListItemIcon,
  Badge,
  Collapse,
  Kind,
  Divider,
  units,
} from '@hbf/dsl/legacy';
import { ArrowButtonUp, ArrowButtonDown } from '@hbf/icons/streamline-bold';
import {
  LayoutDashboard,
  RatingStar,
  MessagesBubbleSquareTyping1,
  LoginKeys,
  AdvertisingBillboardHouse,
  CashPaymentCoins,
  Calendar1,
} from '@hbf/icons/streamline-light';

import { useFreeBookings } from 'ha/helpers/hooks/useFreeBookings';
import { useIntl } from 'ha/i18n';

import { MenuItem } from 'ha/components/Menu/MenuItem';
import { SmartTrack as Track } from 'ha/components/track/SmartTrack';

import { FreeBookingsCounter } from './FreeBookingsCounter';
import { NavBarPayoutListItems } from './NavBarPayoutListItems';

const useStyles = makeStyles()(theme => ({
  sidebar: {
    width: theme.spacing(31),
    paddingBlock: theme.spacing(2.5),
    position: 'relative',
    borderRight: units.border(1, 'solid', theme.palette.mono.main),

    '& .MuiListItemIcon-root': {
      minWidth: 'unset !important',
      paddingRight: theme.spacing(1.5),
    },
    '& .MuiSvgIcon-root': {
      fontSize: '1rem',
      color: theme.palette.grey[700],
    },

    '& .MuiMenuItem-root': {
      '& .MuiListItemText-primary': {
        color: theme.palette.grey[600],
      },
    },

    '& .MuiMenuItem-root.Mui-selected': {
      '& .MuiListItemText-primary': {
        fontWeight: theme.typography.fontWeightBold,
      },
    },

    '& .MuiCollapse-root .MuiMenuItem-root': {
      paddingLeft: theme.spacing(6.5),
      paddingBlock: theme.spacing(0.5),

      '& .MuiListItemText-primary': {
        fontSize: theme.typography.body2.fontSize,
      },
    },
  },

  sidebarContent: {
    position: 'sticky',
    top: theme.spacing(2.5),
  },
  arrowIcon: {
    fontSize: '0.75rem !important',
    color: theme.palette.grey[700],
  },
  inboxItem: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  payoutsItem: {
    backgroundColor: 'transparent !important',
  },
  freeBookingsCounter: {
    padding: theme.spacing(2.25),
  },
}));

interface Props {
  kycStatus: KycStates | undefined;
  unreadMessageCount: number;
}

// TODO: refactor
// eslint-disable-next-line react/function-component-definition
export function NavBarSide({ kycStatus, unreadMessageCount }: Props) {
  const { classes } = useStyles();

  const { pathname } = useLocation();

  const { T, urlResolver } = useIntl();

  const freeBookingsData = useFreeBookings();

  const payoutsSubroutes = React.useMemo(
    () => [
      urlResolver.getPayoutMethodsUrl(),
      urlResolver.getBillingUrl(),
      urlResolver.getTransactionHistoryUrl(),
      urlResolver.getPayoutsPaymentRequests(),
      urlResolver.getInvoicesUrl(),
      urlResolver.getCommissionPlanUrl(),
      urlResolver.getVerifyIdentityUrl(),
    ],
    [urlResolver],
  );

  const [paymentsExpanded, setPaymentsExpanded] = React.useState(() =>
    payoutsSubroutes.includes(pathname),
  );

  const togglePayments = React.useCallback(() => {
    setPaymentsExpanded(previous => !previous);
  }, []);

  return (
    <div className={classes.sidebar}>
      <div className={classes.sidebarContent}>
        <MenuList>
          <Track name="Clicked Dashboard in navigation">
            <MenuItem to={urlResolver.getRMSDashboardUrl()}>
              <ListItemIcon>
                <LayoutDashboard />
              </ListItemIcon>
              <ListItemText primary={T('dashboard.page_title')} />
            </MenuItem>
          </Track>

          <Track name="Clicked Inbox in navigation">
            <MenuItem to={urlResolver.getMyInboxUrl()}>
              <ListItemIcon>
                <MessagesBubbleSquareTyping1 />
              </ListItemIcon>
              <ListItemText primary={T('inbox.page_title')} />

              {unreadMessageCount > 0 && (
                <Badge
                  data-test-locator="NavBarSide - InboxCounters"
                  kind={Kind.inline}
                  color="grey"
                  badgeContent={unreadMessageCount}
                />
              )}
            </MenuItem>
          </Track>

          <Track name="Clicked Listings in navigation">
            <MenuItem to={urlResolver.getMyListingsUrl()}>
              <ListItemIcon>
                <AdvertisingBillboardHouse />
              </ListItemIcon>
              <ListItemText primary={T('listings.page_title')} />
            </MenuItem>
          </Track>

          <Track name="Clicked Calendar in Navigation">
            <MenuItem to={urlResolver.getRMSAvailabilityOverviewUrl()}>
              <ListItemIcon>
                <Calendar1 />
              </ListItemIcon>
              <ListItemText primary={T('availability_overview.nav_title')} />
            </MenuItem>
          </Track>

          <Track name="Clicked Bookings in navigation">
            <MenuItem to={urlResolver.getBookingsUrl()}>
              <ListItemIcon>
                <LoginKeys />
              </ListItemIcon>
              <ListItemText primary={T('bookings.page_title')} />
            </MenuItem>
          </Track>

          <Track name="Clicked Payments in navigation (expanding the menu)">
            <MenuItem onClick={togglePayments} className={classes.payoutsItem}>
              <ListItemIcon>
                <CashPaymentCoins />
              </ListItemIcon>

              <ListItemText primary={T('payments.page_title')} />

              <ListItemIcon>
                {paymentsExpanded ? (
                  <ArrowButtonUp className={classes.arrowIcon} />
                ) : (
                  <ArrowButtonDown className={classes.arrowIcon} />
                )}
              </ListItemIcon>
            </MenuItem>
          </Track>

          <Collapse in={paymentsExpanded} timeout="auto">
            <MenuList disablePadding>
              <NavBarPayoutListItems kycStatus={kycStatus} />
            </MenuList>
          </Collapse>

          <Track name="Clicked Reviews in navigation">
            <MenuItem to={urlResolver.getMyTenantReviewsDashboard()}>
              <ListItemIcon>
                <RatingStar />
              </ListItemIcon>
              <ListItemText primary={T('tenant_reviews.page_title')} />
            </MenuItem>
          </Track>

          {freeBookingsData && (
            <React.Fragment>
              <Divider />
              <div className={classes.freeBookingsCounter}>
                <FreeBookingsCounter freeBookingsData={freeBookingsData} />
              </div>
            </React.Fragment>
          )}
        </MenuList>
      </div>
    </div>
  );
}
