import React from 'react';

import { Chat, Dashboard, House, VpnKey } from '@hbf/icons/brand-regular';
import { useIntl } from 'ha/i18n';
import { SmartTrack as Track } from 'ha/components/track/SmartTrack';
import { Badge } from '@hbf/dsl/core';
import { useInboxCounters } from 'ha/modules/InboxCounter/containers/InboxCounter';
import { NavigationBottomBar } from '../NavigationBottomBar/NavigationBottomBar';
import { AdvertiserLinkDashboard } from '../AdvertiserLinkDashboard/AdvertiserLinkDashboard';

export const AdvertiserNavigationBottomBarItems = () => {
  const { T, urlResolver } = useIntl();

  const { inboxUnread } = useInboxCounters();

  return (
    <React.Fragment>
      <Track name="Clicked Dashboard in the bottom navigation">
        <AdvertiserLinkDashboard component={NavigationBottomBar.Item.Link}>
          <NavigationBottomBar.Item
            slots={{
              icon: (
                <NavigationBottomBar.Item.Icon>
                  <Dashboard />
                </NavigationBottomBar.Item.Icon>
              ),
            }}
          >
            {T('nav.header.dashboard')}
          </NavigationBottomBar.Item>
        </AdvertiserLinkDashboard>
      </Track>
      <Track name="Clicked Inbox in the bottom navigation">
        <NavigationBottomBar.Item.Link href={urlResolver.getMyInboxUrl()}>
          <NavigationBottomBar.Item
            slots={{
              icon: (
                <NavigationBottomBar.Item.Icon>
                  <Badge badgeContent={inboxUnread}>
                    <Chat />
                  </Badge>
                </NavigationBottomBar.Item.Icon>
              ),
            }}
          >
            {T('nav.header.inbox')}
          </NavigationBottomBar.Item>
        </NavigationBottomBar.Item.Link>
      </Track>
      <Track name="Clicked Listings in the bottom navigation">
        <NavigationBottomBar.Item.Link href={urlResolver.getMyListingsUrl()}>
          <NavigationBottomBar.Item
            slots={{
              icon: (
                <NavigationBottomBar.Item.Icon>
                  <House />
                </NavigationBottomBar.Item.Icon>
              ),
            }}
          >
            {T('nav.header.listings')}
          </NavigationBottomBar.Item>
        </NavigationBottomBar.Item.Link>
      </Track>
      <Track name="Clicked Bookings in the bottom navigation">
        <NavigationBottomBar.Item.Link href={urlResolver.getBookingsUrl()}>
          <NavigationBottomBar.Item
            slots={{
              icon: (
                <NavigationBottomBar.Item.Icon>
                  <VpnKey />
                </NavigationBottomBar.Item.Icon>
              ),
            }}
          >
            {T('nav.header.bookings')}
          </NavigationBottomBar.Item>
        </NavigationBottomBar.Item.Link>
      </Track>
    </React.Fragment>
  );
};
