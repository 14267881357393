import { reportError } from 'ha/helpers/bugReporter/reportError';
import { isBrowser } from 'ha/helpers/isBrowser';

import {
  IntercomMethodNames,
  IntercomMethodParams,
  IntercomMethods,
} from '../types';

export const isIntercomInitialised = () =>
  typeof global?.Intercom === 'function';

export const intercom = (
  method: IntercomMethodNames,
  ...args: IntercomMethodParams
) => {
  if (!isBrowser()) {
    return undefined;
  }

  if (isIntercomInitialised()) {
    return global.Intercom(method, ...args);
  }

  return reportError('Intercom is not a function');
};

const show: IntercomMethods['show'] = () => intercom('show');

const showLauncher: IntercomMethods['update'] = () =>
  intercom('update', { hide_default_launcher: false });

const update: IntercomMethods['update'] = userDetails =>
  intercom('update', userDetails);

export const intercomAPI = {
  show,
  showLauncher,
  update,
};
