import React from 'react';

import { connect } from 'react-redux';

import { track } from '@hbf/analytics';

import { TrackContext } from 'ha/components/track/SmartTrack';

type ResultType = (name: string, data?: any) => ReturnType<typeof track>;

export const useTrackEvent = (): ResultType => {
  const parentAttributes = React.useContext(TrackContext);

  return React.useCallback(
    (name, attributes: Record<string, unknown>) => {
      track(name, { ...parentAttributes, ...attributes });
    },
    [parentAttributes],
  );
};

export const withTrackEvent = connect(null, {
  track,
});
