import { Event } from '../types';

export const createIntercomPlaceholder = () => {
  const intercom = (...args: Event) => {
    intercom.c(args);
  };

  // Creates queue
  intercom.q = [] as Event[];

  intercom.c = (args: Event) => {
    intercom.q.push(args);
  };

  window.Intercom = intercom as unknown as Intercom_.IntercomStatic;
};
