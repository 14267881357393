import React from 'react';

import { track } from '@hbf/analytics';

import { useConfig } from 'ha/helpers/config';

export interface UnlessEventDetail {
  event_type: string;
  experience_name: string;
  experience_test: string;
}

const UNLESS_EVENT = 'unless-event';

export const useUnlessScriptTracking = () => {
  const { unless } = useConfig();

  const handleUnlessEvent = React.useCallback((e: CustomEvent<any>) => {
    const detail = e.detail as UnlessEventDetail;
    if (detail?.event_type === 'experience_started') {
      track('Experiment Unless started', {
        'Experiment name': detail.experience_name,
        'Variant name': detail.experience_test,
      });
    }
  }, []);

  React.useEffect(() => {
    if (!unless.enabled) return;

    // Remove previous listener if component re-renders
    document.removeEventListener(UNLESS_EVENT, handleUnlessEvent);

    document.addEventListener(UNLESS_EVENT, handleUnlessEvent);

    // remove listener if component un-mounts
    // eslint-disable-next-line consistent-return
    return () => {
      document.removeEventListener(UNLESS_EVENT, handleUnlessEvent);
    };
  }, [unless, handleUnlessEvent]);
};
