import { useCallback, useEffect, useState } from 'react';
import { useIntl } from 'ha/i18n';
import { useSelector } from 'react-redux';

import { DateTimeFormat } from '@ha/intl';
import { getUserFreeBookingsData } from 'ha/modules/AuthLogic/selectors';
import { isEnabled, useFeatureFlags } from 'ha/modules/FeatureFlags';
import {
  setInStorage,
  getFromStorage,
  removeFromStorage,
} from 'ha/utils/storage';

interface ExtraUseFreeBookingsProps {
  nextRenewalDateFormatted?: string;
  freeBookingsUsagePercentage?: number;
  isFreeBookingsUsedUp?: boolean;
  showExpiredBlock?: boolean;
  handleCloseExpiredUsageCard?: () => void;
}

export type UseFreeBookings =
  | (ReturnType<typeof getUserFreeBookingsData> & ExtraUseFreeBookingsProps)
  | null;

export enum ShowFreeBookingsExpiredConsumer {
  Talk,
  NavBarSide,
}

export const SHOW_FREE_BOOKINGS_EXPIRED_STORAGE_KEYS = {
  [ShowFreeBookingsExpiredConsumer.Talk]:
    'show_free_bookings_expired_card_talk',
  [ShowFreeBookingsExpiredConsumer.NavBarSide]:
    'show_free_bookings_expired_card_navbar_side',
};
interface UseFreeBookingsProps {
  consumer: ShowFreeBookingsExpiredConsumer;
}

export const useFreeBookings = (
  { consumer }: UseFreeBookingsProps | undefined = {
    consumer: ShowFreeBookingsExpiredConsumer.NavBarSide,
  },
): UseFreeBookings => {
  const { formatDateTime } = useIntl();
  const { showFreePricingPlanUsage } = useFeatureFlags();
  const userFreeBookingsData = useSelector(getUserFreeBookingsData);
  const [freeBookingsData, setFreeBookingsData] =
    useState<UseFreeBookings>(null);

  const KEY_NAME = SHOW_FREE_BOOKINGS_EXPIRED_STORAGE_KEYS[consumer];
  const showExpiredBlockStorageValue = getFromStorage(KEY_NAME);
  const [showExpiredBlock, setShowExpiredBlock] = useState<boolean>(
    showExpiredBlockStorageValue !== undefined
      ? Boolean(showExpiredBlockStorageValue)
      : true,
  );

  const handleCloseExpiredUsageCard = useCallback(() => {
    setInStorage(KEY_NAME, false);
    setShowExpiredBlock(false);
  }, [KEY_NAME]);

  // remove `show_free_bookings_expired_card` entry from localStorage,
  // if free usage is still active  && `show_free_bookings_expired_card` value is set as false
  useEffect(() => {
    if (!freeBookingsData || showExpiredBlockStorageValue === undefined) return;
    const { isFreeBookingsUsedUp } = freeBookingsData;
    if (!isFreeBookingsUsedUp && !showExpiredBlockStorageValue) {
      removeFromStorage(KEY_NAME);
    }
  }, [freeBookingsData, showExpiredBlockStorageValue, KEY_NAME]);

  useEffect(() => {
    if (!userFreeBookingsData || !isEnabled(showFreePricingPlanUsage)) return;
    if (userFreeBookingsData.freeBookingsUsage === undefined) return;
    if (!userFreeBookingsData.nextRenewalDate) return;

    const isFreeBookingsUsedUp =
      userFreeBookingsData.freeBookingsUsage >=
      userFreeBookingsData.maxFreeBookings;

    const freeBookingsUsagePercentage =
      (userFreeBookingsData.freeBookingsUsage /
        userFreeBookingsData.maxFreeBookings) *
      100;

    const nextRenewalDateFormatted = formatDateTime(
      new Date(userFreeBookingsData.nextRenewalDate),
      DateTimeFormat.DATE_SHORT,
    );

    setFreeBookingsData({
      ...userFreeBookingsData,
      nextRenewalDateFormatted,
      freeBookingsUsagePercentage,
      isFreeBookingsUsedUp,
      showExpiredBlock,
      handleCloseExpiredUsageCard,
    });
  }, [
    showFreePricingPlanUsage,
    userFreeBookingsData,
    formatDateTime,
    showExpiredBlock,
    handleCloseExpiredUsageCard,
  ]);

  return freeBookingsData;
};
