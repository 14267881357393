import React from 'react';
import { makeStyles } from 'tss-react/mui';
import { Grid, units } from '@hbf/dsl/legacy';

const useStyles = makeStyles()({
  fullHeight: {
    minHeight: units.vh(100),
  },
  grow: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    minWidth: 'min-content',
  },
});

export const StickyFooter: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const { classes } = useStyles();
  return (
    <Grid
      className={classes.fullHeight}
      direction="column"
      wrap="nowrap"
      container
    >
      <div className={classes.grow}>{children}</div>
    </Grid>
  );
};
