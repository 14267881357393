import React from 'react';
import { makeStyles } from 'tss-react/mui';
import { DynamicIslandContext } from './context';

const useStyles = makeStyles()(theme => ({
  container: {
    paddingInline: theme.spacing(6),
  },
}));

const HeaderDynamicIslandContainer = ({
  children,
}: React.PropsWithChildren<unknown>) => {
  const { classes } = useStyles();

  return <div className={classes.container}>{children}</div>;
};

export const HeaderDynamicIsland = ({
  children,
}: React.PropsWithChildren<unknown>) => {
  const { setIsDynamicIslandShown } = DynamicIslandContext.useContext();

  React.useEffect(() => {
    setIsDynamicIslandShown(true);

    return () => setIsDynamicIslandShown(false);
  }, [setIsDynamicIslandShown]);

  return children;
};

HeaderDynamicIsland.Container = HeaderDynamicIslandContainer;
