/**
 * RegExp that matches Imgix URL
 * - https://housinganywhere.imgix.net/$OWNER_TYPE/$OWNER_ID/$IMAGE_UUID.jpg
 * - https://housinganywhere-stage.imgix.net/$OWNER_TYPE/$OWNER_ID/$IMAGE_UUID.jpg
 */
export const IMGIX_URL_RE =
  /^https:\/\/housinganywhere(.*)\.imgix\.net\/(?:room|unit_type|hauser|uni_logo|uni_photo)\/\d+\/[0-9a-f-]+\.jpg/i;

export const ImgixSize = {
  default: { w: 490, h: 300 },

  thumbnailXxSmall: { w: 44, h: 44 },
  thumbnailXSmall: { w: 58, h: 58 },
  thumbnailSmall: { w: 80, h: 80 },
  thumbnailMedium: { w: 112, h: 112 },
  thumbnailLarge: { w: 160, h: 160 },
  thumbnailXLarge: { w: 240, h: 240 },

  // card/preview sizes with 3:2 aspect ratio
  xxxsmall: { w: 120, h: 90 },
  xxsmall: { w: 225, h: 150 },
  xsmall: { w: 360, h: 240 },
  small: { w: 450, h: 300 },
  medium: { w: 768, h: 512 },
  large: { w: 1050, h: 700 },
  xlarge: { w: 1860, h: 1240 },

  // fullscreen sizes
  fullscreenDesktopSmall: { w: 1440, h: 701 },
  fullscreenDesktopMedium: { w: 1920, h: 881 },
  fullscreenDesktopLarge: { w: 2560, h: 1241 },
  fullscreenMobileSmall: { w: 375, h: 595 },
  fullscreenMobileMedium: { w: 414, h: 664 },
  fullscreenMobileLarge: { w: 768, h: 952 },
} as const;
