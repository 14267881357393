import React from 'react';

import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';
import { useIntl } from 'ha/i18n';
import { useTitle } from 'ha/helpers/hooks/useTitle';
import { useImgix } from 'ha/modules/Image/useImgix';
import { useConfig } from 'ha/helpers/config';

export const Meta: React.FC = () => {
  const { T, urlResolver } = useIntl();
  const location = useLocation();
  const defaultTitle = useTitle(
    'HousingAnywhere: Mid to Long-Term Rentals in 400+ cities.',
  );

  const { transformImageURL } = useImgix();

  const { imgix } = useConfig();
  const imgSrc = `${imgix.sourceURL}/static/logo-1200x630.png`;

  return (
    <Helmet>
      <title key="title">{defaultTitle}</title>
      <meta
        key="meta description"
        name="description"
        content={T('meta_description')}
      />
      <meta
        key="meta og:description"
        property="og:description"
        content={T('meta_description')}
      />
      <meta
        key="meta og:type"
        property="og:type"
        content="housinganywhere:listing"
      />
      <meta
        key="meta og:site_name"
        property="og:site_name"
        content="HousingAnywhere"
      />
      <meta
        key="meta og:url"
        property="og:url"
        content={urlResolver.absolutizePath(location.pathname, {
          skipAddLanguage: true,
        })}
      />
      <meta key="meta og:title" property="og:title" content="HousingAnywhere" />
      <meta
        key="meta og:image"
        property="og:image"
        content={transformImageURL(imgSrc)}
      />
      {(location.pathname === '/my' || location.pathname.includes('/my/')) && (
        <meta key="meta robots" name="robots" content="noindex" />
      )}
    </Helmet>
  );
};
