import {
  getUserTraits,
  getAnalyticsUserData,
} from 'ha/modules/AuthLogic/selectors';
import { notEmpty } from 'ha/utils/notEmpty';

export type UserData = {
  userId: string;
} & ReturnType<typeof getAnalyticsUserData> &
  ReturnType<typeof getUserTraits>;

export const transformUserData = (rawData: UserData) => {
  const payload: Intercom_.IntercomSettings = {};

  Object.keys(rawData).forEach(key => {
    if (notEmpty(rawData[key])) {
      switch (key) {
        case 'userId':
          payload.user_id = rawData[key].toString();
          break;

        case 'firstName':
          payload.name = payload.name
            ? `${rawData[key]} ${payload.name}`.trim()
            : rawData[key];
          break;

        case 'lastName':
          payload.name = payload.name
            ? `${payload.name} ${rawData[key]}`.trim()
            : rawData[key];
          break;

        case 'createdAt':
          payload.created_at = Math.floor(Date.parse(rawData[key]) / 1000) || 0;
          break;

        default:
          payload[key] = rawData[key];
          break;
      }
    }
  });

  return payload;
};
