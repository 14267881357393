import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { getListingsCount } from '../selectors';
import { getFavoriteListings } from '../actions';

const useFavoriteListings = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getFavoriteListings());
  }, [dispatch]);

  const favoriteListingsCount = useSelector(getListingsCount);

  return { favoriteListingsCount };
};

export { useFavoriteListings };
