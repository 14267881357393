/* eslint-disable react/jsx-no-constructed-context-values */
import React from 'react';

import ResizeObserver from 'resize-observer-polyfill';
import { makeStyles } from 'tss-react/mui';

import { usePageComponentsInfo } from 'ha/modules/Page/contexts/PageComponentsInfoContext';

import { DynamicIslandContext } from '../HeaderDynamicIsland/context';

const useStyles = makeStyles()(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  containerSticky: {
    position: 'sticky',
    top: '0',
    zIndex: theme.zIndex.appBar,
  },
  appBar: {
    background: theme.palette.neutral[100],
    padding: theme.spacing(4, 5),

    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(5, 8 /* 10 */),
    },
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(5, 9 /* 20 */),
    },
    maxWidth: '100vw',
  },
  appBarWithBorder: {
    borderBottom: `1px solid ${theme.palette.mono.main}`,
  },
  appBarLayout: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',

    [theme.breakpoints.up('lg')]: {
      display: 'grid',
      gridAutoFlow: 'column',
      gridTemplateColumns: '1fr [start] auto [middle] 1fr [end]',
    },
  },
  logo: {
    display: 'flex',
    justifyContent: 'flex-start',
    minWidth: 'max-content',
  },
  dynamicIsland: {
    flexGrow: 2,
    minWidth: 0,
  },
  quickActions: {
    display: 'flex',
    justifyContent: 'flex-end',
    minWidth: 'max-content',
  },
}));

interface NavigationAppBarProps {
  sticky?: boolean;
  slots: {
    logo: React.ReactNode;
    dynamicIsland?: React.ReactNode;
    quickActions?: React.ReactNode;

    above?: React.ReactNode;
    below?: React.ReactNode;
  };
  className?: string;
  showBorder?: boolean;
}

export const NavigationAppBar = ({
  sticky = false,
  slots,
  className,
  showBorder = true,
}: NavigationAppBarProps) => {
  const { classes, cx } = useStyles();

  const containerRef = React.useRef<HTMLDivElement>(null);

  const { setTopNavbarHeight } = usePageComponentsInfo();

  const [isDynamicIslandShown, setIsDynamicIslandShown] = React.useState(
    slots.dynamicIsland !== undefined,
  );

  React.useEffect(() => {
    const container = containerRef.current;
    if (!container) return undefined;

    const updateBottomNavbarHeigh = () => {
      setTopNavbarHeight(container.offsetHeight);
    };

    const resizeObserver = new ResizeObserver(updateBottomNavbarHeigh);

    resizeObserver.observe(container);

    return () => resizeObserver.disconnect();
  }, [setTopNavbarHeight]);

  return (
    <React.Fragment>
      <div
        data-test-locator="Navigation Header"
        ref={containerRef}
        className={cx(
          classes.container,
          sticky && classes.containerSticky,
          className,
        )}
      >
        {slots.above}
        <div
          className={cx(classes.appBar, {
            [classes.appBarWithBorder]: showBorder,
          })}
        >
          <div className={classes.appBarLayout}>
            <DynamicIslandContext.Provider
              value={{ isDynamicIslandShown, setIsDynamicIslandShown }}
            >
              <div className={classes.logo}>{slots.logo}</div>
              <div className={classes.dynamicIsland}>{slots.dynamicIsland}</div>
              <div className={classes.quickActions}>{slots.quickActions}</div>
            </DynamicIslandContext.Provider>
          </div>
        </div>
      </div>
      {slots.below}
    </React.Fragment>
  );
};
