import React from 'react';
import { useSelector } from 'react-redux';

import { isEnabled, useFeatureFlags } from 'ha/modules/FeatureFlags';
import { getInboxUnreadCount } from 'ha/modules/InboxCounter/selectors';
import { getIsAuthenticated } from 'ha/modules/AuthLogic/selectors';
import { PAGE_TITLE_SUFFIX } from '../constants';

interface Options {
  withSuffix?: boolean;
}

export const useTitle = (title: string, options?: Options) => {
  const unreadCount = useSelector(getInboxUnreadCount);
  const isAuthenticated = useSelector(getIsAuthenticated);
  const { showUnreadCount } = useFeatureFlags();
  const showCounter = isEnabled(showUnreadCount);

  const [mounted, setMounted] = React.useState(false);

  React.useEffect(() => {
    setMounted(true);
  }, []);

  const prefix =
    showCounter && isAuthenticated && mounted && unreadCount > 0
      ? `(${unreadCount}) `
      : '';
  const suffix = options?.withSuffix ? PAGE_TITLE_SUFFIX : '';

  return [prefix, title, suffix].join('');
};
