import React from 'react';

import { Helmet } from 'react-helmet-async';

import { generateLoadingScript } from '../utils/generateLoadingScript';
import { generateFontFaceStyle } from '../utils/generateFontFaceStyle';
import { FontInfo } from '../types';

export interface Props {
  activeFonts: FontInfo[];
}

// inspired by https://www.zachleat.com/web/css-tricks-web-fonts/
const LoadFonts: React.FC<Props> = ({ activeFonts }) => {
  const preloadFiles = activeFonts.flatMap(
    font => font.preloadWoff2Files || [],
  );

  return (
    <Helmet>
      {preloadFiles.map(file => (
        <link
          key={file}
          rel="preload"
          href={file}
          as="font"
          type="font/woff2"
          crossOrigin="anonymous"
        />
      ))}

      <script>{generateLoadingScript(activeFonts)}</script>

      <style key="LoadFonts style">
        {activeFonts
          .flatMap(font =>
            font.stage1Files.map(file =>
              generateFontFaceStyle(font.fontName, file),
            ),
          )
          .join(' ')}
      </style>
    </Helmet>
  );
};

export { LoadFonts };
