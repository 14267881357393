/* eslint-disable react/jsx-no-constructed-context-values */
import { GlobalState } from 'ha/types/store';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import React from 'react';
import { Globe as GlobeBold, Translate } from '@hbf/icons/brand-bold';
import { Globe as GlobeRegular } from '@hbf/icons/brand-regular';
import { createRequiredContext } from 'ha/core/RequiredContext';
import {
  ListItemProps,
  IconButton,
  Select,
  SelectProps,
  Dropdown,
  List,
} from '@hbf/dsl/core';
import { Language } from '../types';
import { getCurrentPageLanguages, getCurrentLanguageCode } from '../selectors';
import { changeLanguage } from '../actions/changeLanguage';

const LanguageSwitcherCompactContext = createRequiredContext<{
  onChange: (language: Language) => void;
  currentLanguage: Language;
}>('LanguageSwitcherCompact');

interface LanguageSwitcherCompactProps {
  onChange: (language: Language) => void;
  currentLanguage: Language;
}

export const LanguageSwitcherCompact = ({
  children,
  onChange,
  currentLanguage,
}: React.PropsWithChildren<LanguageSwitcherCompactProps>) => {
  const buttonRef = React.useRef<HTMLButtonElement>(null);

  const [isOpen, setIsOpen] = React.useState(false);

  return (
    <LanguageSwitcherCompactContext.Provider
      value={{ onChange, currentLanguage }}
    >
      <IconButton
        aria-label="Language Switcher"
        aria-controls="language-switcher"
        aria-haspopup="dialog"
        aria-expanded={isOpen ? 'true' : 'false'}
        data-test-locator="Language switcher"
        ref={buttonRef}
        onClick={() => setIsOpen(true)}
      >
        <Translate />
      </IconButton>

      <Dropdown
        id="language-switcher"
        anchorEl={buttonRef.current}
        open={isOpen}
        onClose={() => setIsOpen(false)}
      >
        <List size="lg">{children}</List>
      </Dropdown>
    </LanguageSwitcherCompactContext.Provider>
  );
};

const LanguageSwitcherCompactItem = ({ language }: { language: Language }) => {
  const { onChange, currentLanguage } =
    LanguageSwitcherCompactContext.useContext();

  return (
    <List.Item
      selected={language === currentLanguage}
      onClick={() => onChange(language)}
      data-test-locator={`Option ${language.code}`}
    >
      {language.localisedText}
    </List.Item>
  );
};

LanguageSwitcherCompact.Item = LanguageSwitcherCompactItem;

interface LanguageSwitcherFullProps {
  onChange: (language: Language) => void;
  currentLanguage: Language;
  children: JSX.Element[];
  appearance?: SelectProps['appearance'];
}

export const LanguageSwitcherFull = ({
  children,
  onChange,
  currentLanguage,
  appearance,
}: LanguageSwitcherFullProps) => {
  const [language, setLanguage] = React.useState<Language>(currentLanguage);

  const handleChange = (_: unknown, child: React.ReactNode) => {
    if (!React.isValidElement(child)) return;

    const nextLanguage = child.props.language as Language;

    setLanguage(nextLanguage);
    onChange(nextLanguage);
  };

  return (
    <Select
      labelPrefix={
        appearance === 'primary' ? (
          <GlobeRegular fontSize="small" />
        ) : (
          <GlobeBold fontSize="small" />
        )
      }
      size={appearance === 'primary' ? 'md' : 'sm'}
      value={language.code}
      renderValue={() => language.localisedText}
      onChange={handleChange}
      appearance={appearance}
    >
      {children.map(child =>
        React.cloneElement(child, {
          ...child.props,
          // hack around MUI's insistence to inspect children and their props
          value: (child.props.language as Language).code,
        }),
      )}
    </Select>
  );
};

interface LanguageSwitcherFullItemProps extends ListItemProps {
  language: Language;
}

const LanguageSwitcherFullItem = ({
  language,
  ...rest
}: LanguageSwitcherFullItemProps) => {
  return (
    <Select.Item {...rest} value={language.code}>
      {language.localisedText}
    </Select.Item>
  );
};

LanguageSwitcherFull.Item = LanguageSwitcherFullItem;

interface LanguageSwitcherProps {
  size: 'icon' | 'full-primary' | 'full-secondary';
}

export const LanguageSwitcher: React.FC<LanguageSwitcherProps> = ({ size }) => {
  const dispatch = useDispatch();

  const location = useLocation();

  const languages = useSelector(state =>
    getCurrentPageLanguages(state as GlobalState, location),
  );

  const currentLanguageCode = useSelector(getCurrentLanguageCode);
  const currentLanguage = React.useMemo(() => {
    return (
      languages.find(language => language.code === currentLanguageCode) ??
      languages[0]
    );
  }, [currentLanguageCode, languages]);

  const handleLanguageChange = React.useCallback(
    (language: Language) => {
      dispatch(changeLanguage(language.code, location));
    },
    [dispatch, location],
  );

  if (size === 'icon') {
    return (
      <LanguageSwitcherCompact
        currentLanguage={currentLanguage}
        onChange={handleLanguageChange}
      >
        {languages.map(language => (
          <LanguageSwitcherCompact.Item
            language={language}
            key={language.code}
          />
        ))}
      </LanguageSwitcherCompact>
    );
  }

  return (
    <LanguageSwitcherFull
      currentLanguage={currentLanguage}
      onChange={handleLanguageChange}
      appearance={size === 'full-primary' ? 'primary' : 'secondary'}
    >
      {languages.map(language => (
        <LanguageSwitcherFull.Item language={language} key={language.code} />
      ))}
    </LanguageSwitcherFull>
  );
};
