export const INTERCOM_SCRIPT_URL_BASE = 'https://widget.intercom.io/widget/';

export const loadIntercomScript = (
  applicationID: string,
  onLoad: () => void,
  onError: (e: Error) => void,
) => {
  const script = document.createElement('script');
  script.type = 'text/javascript';
  script.src = `${INTERCOM_SCRIPT_URL_BASE}${applicationID}`;
  script.async = true;
  script.addEventListener('load', () => onLoad(), false);
  script.addEventListener(
    'error',
    error => {
      onError(error.error);
    },
    false,
  );
  document.head.appendChild(script);
};
