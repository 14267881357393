import React from 'react';

import { useLocation } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';

import { HttpStatus } from '@ha/api/v2/handleApiV2Response';

import { OTHER } from 'ha/constants/pageNames';

import { useMediaQuery } from 'ha/helpers/MediaQuery';
import { onPageMount } from 'ha/modules/Analytics/actions';
import {
  getCurrentLanguage,
  getIsAdvertiser,
  getIsAuthenticated,
} from 'ha/modules/AuthLogic/selectors';
import { useCookieConsent } from 'ha/modules/CookieConsent';
import { useUnlessScriptTracking } from 'ha/modules/CookieConsent/hooks/useUnlessScriptTracking';
import { getFromStorage, removeFromStorage } from 'ha/utils/sessionStorage';

import { StatusCode } from 'ha/modules/StatusCode';

import { PageLayout } from '../components/PageLayout';
import { PageProvider, PageComponentsInfoProvider } from '../contexts';
import { PageProps } from '../types';

export const Page: React.FC<React.PropsWithChildren<PageProps>> = ({
  activeFonts,
  useLegacyFonts,
  category = OTHER,
  children,
  fixedFooterContent,
  globalBanner,
  hasFooter,
  hasHeader,
  slots,
  name,
  pageBanner,
  remarketingTag,
  showBottomNav,
  searchBarElement,
  hasStickyNav,
  statusCode = HttpStatus.OK,
  traits = {},
  hasProgressBar,
  hasSidebar,
  flexContent,
  hideCTA,
  authLinkInfo,
  sendAnalyticsEvent = true,
}) => {
  const location = useLocation();
  const { lg, md, xl } = useMediaQuery();

  const isAuthenticated = useSelector(getIsAuthenticated);
  const isAdvertiser = useSelector(getIsAdvertiser);

  const allowSidebar = React.useMemo(() => {
    return (md || lg || xl) && isAdvertiser;
  }, [md, lg, xl, isAdvertiser]);

  const language = useSelector(getCurrentLanguage);
  const dispatch = useDispatch();

  useCookieConsent();
  useUnlessScriptTracking();

  React.useEffect(() => {
    if (!sendAnalyticsEvent) {
      return;
    }

    const eventHandler = () => {
      dispatch(
        onPageMount({
          category,
          name,
          traits: { ...traits, language },
          remarketingTag,
        }),
      );
    };

    // fix analytics issue with back navigation reporting incorrect URL
    setTimeout(eventHandler, 200);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sendAnalyticsEvent]);

  React.useEffect(() => {
    if (location.pathname.includes('my/book/contact')) return;

    const shouldShowHotjarPoll = getFromStorage('unregisteredBookingFlow');
    if (shouldShowHotjarPoll) {
      window.hj?.('trigger', 'unregistered_bookingflow_dropoff_poll');
      removeFromStorage('unregisteredBookingFlow');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PageProvider
      name={name}
      category={category}
      authLinkInfo={authLinkInfo || {}}
    >
      <PageComponentsInfoProvider>
        <StatusCode status={statusCode} />
        <PageLayout
          activeFonts={activeFonts}
          useLegacyFonts={useLegacyFonts}
          fixedFooterContent={fixedFooterContent}
          globalBanner={globalBanner}
          hasFooter={hasFooter}
          hasHeader={hasHeader}
          slots={slots}
          hasSidebar={allowSidebar && hasSidebar}
          hasProgressBar={hasProgressBar}
          hasStickyNav={hasStickyNav}
          isAuthenticated={isAuthenticated}
          pageBanner={pageBanner}
          showBottomNav={showBottomNav}
          searchBarElement={searchBarElement}
          flexContent={flexContent}
          hideCTA={hideCTA}
        >
          {children}
        </PageLayout>
      </PageComponentsInfoProvider>
    </PageProvider>
  );
};
