import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { ConversationsCounters } from '@ha/api/v2/types/ConversationsCounters';

import { getUserId } from 'ha/modules/AuthLogic/selectors';
import { getIsLoaded, getInboxCounts } from '../selectors';

import { getInboxCounters } from '../actions';

interface InboxCounterChildren {
  inboxCounts: ConversationsCounters;
  isLoaded: boolean;
}

export function useInboxCounters() {
  const dispatch = useDispatch();
  const isLoaded = useSelector(getIsLoaded);
  const userId = useSelector(getUserId);
  const inboxCounts = useSelector(getInboxCounts);

  React.useEffect(() => {
    if (userId && !isLoaded) {
      dispatch(getInboxCounters(userId));
    }
  }, [dispatch, isLoaded, userId]);

  return inboxCounts;
}

interface Props {
  children: ({
    inboxCounts,
    isLoaded,
  }: InboxCounterChildren) => JSX.Element | null;
}

const InboxCounterContainer: React.FC<Props> = ({ children }) => {
  const isLoaded = useSelector(getIsLoaded);
  const inboxCounts = useInboxCounters();

  return children({
    inboxCounts,
    isLoaded,
  });
};

export { InboxCounterContainer };
