import { FontFile } from '../types';

const generateFontFaceStyle = (fontName: string, file: FontFile) =>
  `@font-face { font-family: '${fontName}'; src: ${file.src}; ${
    file.options.style ? `font-style: ${file.options.style}; ` : ''
  }${file.options.weight ? `font-weight: ${file.options.weight}; ` : ''}${
    file.options.synthesis ? `font-synthesis: ${file.options.synthesis}; ` : ''
  }${
    file.options.unicodeRange
      ? `unicode-range: ${file.options.unicodeRange}; `
      : ''
  }${
    file.options.featureSettings
      ? `font-feature-settings: ${file.options.featureSettings}; `
      : ''
  }${file.options.stretch ? `font-stretch: ${file.options.stretch}; ` : ''}${
    file.options.display ? `font-display: ${file.options.display}; ` : ''
  }}`;

export { generateFontFaceStyle };
