import React from 'react';

import { Button } from '@hbf/dsl/core';
import { ExtendProps, ExtendedComponent } from '../ExtendedComponent/types';

type ButtonProps = React.ComponentProps<typeof Button>;

export const CallToActionButton = React.forwardRef(
  <CustomComponent extends ExtendedComponent<Pick<ButtonProps, 'variant'>>>(
    { children, ...rest }: ButtonProps | ExtendProps<CustomComponent>,
    ref: React.ForwardedRef<React.ComponentRef<typeof Button>>,
  ) => {
    return (
      <Button
        ref={ref}
        variant="outlined"
        color="neutral"
        data-test-locator="Navigation/CallToAction"
        {...rest}
      >
        {children}
      </Button>
    );
  },
);
