import { AlertProps as DslAlertProps } from '@hbf/dsl/core';

export enum AlertType {
  Success = 'success',
  Warning = 'warning',
  Danger = 'danger',
  Info = 'info',
}

export enum AlertDismissAfter {
  Default = 5000,
  Short = 2000,
  Long = 10000,
}

export interface AlertProps {
  id?: string;
  text: React.ReactNode;
  description?: React.ReactNode;
  kind?: AlertType;
  variant?: DslAlertProps['variant'];
  dismissAfter?: number;
  persistent?: boolean;
  /** Pass a unique identifier. The previous alert will be immediately replaced if its ID matches the next one */
  replaceSameAlert?: string;
}
