import React from 'react';

import { Cookies } from 'ha/constants/Cookies';

import { CampaignKeywords } from 'ha/modules/Analytics/constants';
import { useServices } from 'ha/services';

import { StudentenWG, Zwischenmitte, Rentmate, StanzaZoo } from './constants';

export function useKrugerNotification(searchParams: URLSearchParams) {
  const utmSource = searchParams.get(CampaignKeywords.UtmSource) ?? '';

  const {
    cookie: { getCookie, setCookie },
  } = useServices();
  const [open, setOpen] = React.useState(false);

  React.useEffect(() => {
    const cookie = getCookie(Cookies.KRUGER_VISITED);

    if (
      cookie !== '1' &&
      [StudentenWG, Zwischenmitte, Rentmate, StanzaZoo].includes(utmSource)
    ) {
      setOpen(true);
    }
  }, []);

  const handleClose = React.useCallback(() => {
    setCookie(Cookies.KRUGER_VISITED, '1');
    setOpen(false);
  }, []);

  return {
    open,
    handleClose,
    utmSource,
  };
}
