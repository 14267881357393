import React from 'react';

import { useIntl } from 'ha/i18n';

import {
  ExtendProps,
  ExtendedComponent,
  ExtendedExternalLinkProps,
} from '../ExtendedComponent/types';

type RequiredProps = ExtendedExternalLinkProps;

export const AdvertiserLinkDashboard = React.forwardRef(
  <CustomComponent extends ExtendedComponent<RequiredProps>>(
    { children, component, ...rest }: ExtendProps<CustomComponent>,
    ref: React.ForwardedRef<React.ComponentType<CustomComponent>>,
  ) => {
    const { urlResolver } = useIntl();

    const Component = component as ExtendedComponent<RequiredProps>;

    return (
      <Component
        ref={ref}
        {...rest}
        component="a"
        href={urlResolver.getRMSDashboardUrl()}
      >
        {children}
      </Component>
    );
  },
);
