import React from 'react';

import { AuthPageQueryParams, AuthPageTarget } from 'ha/helpers/UrlResolver';
import { useIntl } from 'ha/i18n';
import { extendForwardedRef } from 'ha/utils/extendForwardedRef';

import { usePage } from 'ha/modules/Page/contexts/PageContext';

import {
  ExtendedComponent,
  ExtendProps,
  ExtendedClickableProps,
  ExtendedExternalLinkProps,
} from '../ExtendedComponent/types';

const AuthButtonRegisterLabel = () => {
  const { T } = useIntl();

  return T('nav.header.sign_up');
};

const AuthButtonRegisterForwarded = React.forwardRef(
  <
    CustomComponent extends ExtendedComponent<
      ExtendedClickableProps | ExtendedExternalLinkProps
    >,
  >(
    { component, children, ...rest }: ExtendProps<CustomComponent>,
    ref: React.ForwardedRef<React.ComponentRef<CustomComponent>>,
  ) => {
    const { urlResolver } = useIntl();

    const { authLinkInfo } = usePage();
    const { continueURL, queryParams } = authLinkInfo;

    const signUpUrlQueryParams = React.useMemo(() => {
      const params: AuthPageQueryParams = {
        target: queryParams?.target || AuthPageTarget.Tenant,
      };

      return params;
    }, [queryParams?.target]);

    const signUpUrl = urlResolver.getOAuthSignupUrl(
      continueURL,
      signUpUrlQueryParams,
    );

    const Component = component as ExtendedComponent<
      ExtendedClickableProps | ExtendedExternalLinkProps
    >;

    return (
      <Component
        ref={ref}
        component="a"
        href={signUpUrl}
        {...rest}
        data-test-locator="Navigation/RegisterButton"
      >
        {children ?? <AuthButtonRegisterLabel />}
      </Component>
    );
  },
);

export const AuthButtonRegister = extendForwardedRef(
  AuthButtonRegisterForwarded,
  {
    Label: AuthButtonRegisterLabel,
  },
);
