import React from 'react';

import { makeStyles } from 'tss-react/mui';
import { HeaderQuickActionsContext } from './context';

const useStyles = makeStyles()(theme => ({
  block: {
    display: 'flex',
    gap: theme.spacing(4),
    alignItems: 'center',
    justifyContent: 'space-between',
    flexWrap: 'nowrap',
  },
}));

const HeaderQuickActionsBlock = ({
  children,
}: React.PropsWithChildren<unknown>) => {
  const { classes } = useStyles();

  return <div className={classes.block}>{children}</div>;
};

export const HeaderQuickActions = ({
  children,
}: React.PropsWithChildren<unknown>) => {
  const { classes } = useStyles();

  return <div className={classes.block}>{children}</div>;
};

HeaderQuickActions.Block = HeaderQuickActionsBlock;
HeaderQuickActions.Context = HeaderQuickActionsContext;
