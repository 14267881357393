import React from 'react';

interface PageComponentsInfoContextInterface {
  /**
   * For small screens, we have a bottom navbar on all pages when user is logged in.
   * Use this height it you want to add a sticky element to the page that needs to be on the top of the bottom navbar!
   */
  bottomNavbarHeight?: number;
  setBottomNavbarHeight: (height: number) => void;

  topNavbarHeight?: number;
  setTopNavbarHeight: (height: number) => void;
}

const PageComponentsInfoContext =
  React.createContext<PageComponentsInfoContextInterface>({
    setBottomNavbarHeight: () => {},
    setTopNavbarHeight: () => {},
  });

export const PageComponentsInfoProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const [bottomNavbarHeight, setBottomNavbarHeight] = React.useState<number>();
  const [topNavbarHeight, setTopNavbarHeight] = React.useState<number>();

  const value = React.useMemo(
    () => ({
      bottomNavbarHeight,
      setBottomNavbarHeight,
      topNavbarHeight,
      setTopNavbarHeight,
    }),
    [bottomNavbarHeight, topNavbarHeight],
  );

  return (
    <PageComponentsInfoContext.Provider value={value}>
      {children}
    </PageComponentsInfoContext.Provider>
  );
};

export const usePageComponentsInfo = () =>
  React.useContext(PageComponentsInfoContext);

export const PageStyleConsumer = PageComponentsInfoContext.Consumer;
