import React from 'react';
import { buildURL, SharedImigixAndSourceProps } from 'react-imgix';

import { useConfig } from 'ha/helpers/config';
import { IMGIX_URL_RE } from './constants';
import { isValidImgixSource } from './isValidImgixSource';

const ROTATION_VALUES = ['0', '90', '180', '270'];

type TransformImageURL = (
  src: string,
  params?: SharedImigixAndSourceProps['imgixParams'],
) => string;

interface UseImgixResponse {
  transformImageURL: TransformImageURL;
}

export const useImgix = (): UseImgixResponse => {
  const { imgix } = useConfig();

  const transformImageURL: TransformImageURL = React.useCallback(
    (src, params) => {
      if (!isValidImgixSource(src)) {
        return src;
      }

      const parsedUrl = new URL(src);

      const path = parsedUrl.pathname.replace('/v2', '').replace('.jpg', '');

      let orientation: string | undefined;

      let urlSearchParams: URLSearchParams | undefined;

      if (parsedUrl.search && typeof URLSearchParams !== 'undefined') {
        urlSearchParams =
          // @ts-ignore
          parsedUrl.searchParams || new URLSearchParams(parsedUrl.search);
      }

      let ownerType: string;
      let ownerId: string;
      let imageId: string;

      if (IMGIX_URL_RE.test(src)) {
        [, ownerType, ownerId, imageId] = path.split('/');

        if (urlSearchParams) {
          if (!orientation && urlSearchParams.has('orient')) {
            const value = urlSearchParams.get('orient');

            orientation =
              value && value !== '0' && ROTATION_VALUES.includes(value)
                ? value
                : undefined;
          }
        }
      } else {
        return src;
      }

      const baseUrl = `${imgix.sourceURL}/${ownerType}/${ownerId}/${imageId}.jpg`;

      return buildURL(
        baseUrl,
        orientation ? { ...params, orient: orientation } : params,
      );
    },
    [imgix],
  );

  const result = React.useMemo(
    () => ({ transformImageURL }),
    [transformImageURL],
  );

  return result;
};
