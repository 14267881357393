import React from 'react';

import { useMediaQuery, useTheme } from '@mui/material';
import { useSelector } from 'react-redux';

import { makeStyles } from 'tss-react/mui';

import { Button, IconButton } from '@hbf/dsl/core';
import { ArrowLeft } from '@hbf/icons/brand-bold';

import {
  getIsAdvertiser,
  getIsAuthenticated,
} from 'ha/modules/AuthLogic/selectors';

import { DefaultLogo as BaseDefaultLogo } from 'ha/modules/Navigation/NavigationHeader';

import { AdminImpersonationBanner } from './components/AdminImpersonationBanner/AdminImpersonationBanner';
import { LoadableAdvertiserQuickActionsMinimized } from './components/AdvertiserQuickActionsMinimized/AdvertiserQuickActionsMinimized.lazy';
import { LoggedOutQuickActionsMinimized } from './components/LoggedOutQuickActionsMinimized/LoggedOutQuickActionsMinimized';
import { NavigationAppBar } from './components/NavigationAppBar/NavigationAppBar';
import { NavigationLogoProps } from './components/NavigationLogo/NavigationLogo';
import { LoadableTenantQuickActionsMinimized } from './components/TenantQuickActionsMinimized/TenantQuickActionsMinimized.lazy';

const useStyles = makeStyles()(() => ({
  logoContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
}));

export const DefaultLogo = ({ size }: Pick<NavigationLogoProps, 'size'>) => {
  const { classes } = useStyles();

  return (
    <div className={classes.logoContainer}>
      <BaseDefaultLogo size={size} />
    </div>
  );
};

type ButtonProps = {
  onClick: () => void;
  children: React.ReactNode;
};

export const BackButton = React.forwardRef(
  (
    { onClick, children }: ButtonProps,
    ref: React.ForwardedRef<React.ComponentRef<typeof Button>>,
  ) => {
    const theme = useTheme();
    const isLargerThanMd = useMediaQuery(theme.breakpoints.up('md'));
    return isLargerThanMd ? (
      <Button
        variant="text"
        color="neutral"
        startIcon={<ArrowLeft />}
        size="small"
        onClick={onClick}
        ref={ref}
      >
        {children}
      </Button>
    ) : (
      <IconButton onClick={onClick}>
        <ArrowLeft />
      </IconButton>
    );
  },
);

const DefaultQuickActions = () => {
  const isAuthenticated = useSelector(getIsAuthenticated);
  const isAdvertiser = useSelector(getIsAdvertiser);

  if (isAuthenticated) {
    if (isAdvertiser) return <LoadableAdvertiserQuickActionsMinimized />;
    return <LoadableTenantQuickActionsMinimized />;
  }

  return <LoggedOutQuickActionsMinimized />;
};

interface NavigationHeaderMinimizedProps {
  sticky?: boolean;
  slots?: {
    appBar?: typeof NavigationAppBar;

    logo?: React.ReactNode;
    dynamicIsland?: React.ReactNode;
    quickActions?: React.ReactNode;

    above?: React.ReactNode;
    below?: React.ReactNode;
    goBackButton?: React.ReactNode;
  };
}

export const NavigationHeaderMinimized = ({
  sticky = false,
  slots = {},
}: NavigationHeaderMinimizedProps) => {
  const { appBar: AppBar = NavigationAppBar } = slots;

  const theme = useTheme();
  const isLargerThanMd = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <React.Fragment>
      <AdminImpersonationBanner />
      <AppBar
        showBorder={!isLargerThanMd}
        sticky={sticky}
        slots={{
          above: slots.above,
          below: slots.below,
          logo: slots.goBackButton,
          dynamicIsland: slots.dynamicIsland || (
            <NavigationHeaderMinimized.DefaultLogo />
          ),
          quickActions: slots.quickActions || (
            <NavigationHeaderMinimized.DefaultQuickActions />
          ),
        }}
      />
    </React.Fragment>
  );
};

NavigationHeaderMinimized.BackButton = BackButton;
NavigationHeaderMinimized.DefaultLogo = DefaultLogo;
NavigationHeaderMinimized.DefaultQuickActions = DefaultQuickActions;
