import React from 'react';
import { makeStyles } from 'tss-react/mui';
import { useLocation } from 'react-router-dom';
import { MenuItemProps, MenuItem as MenuItemMUI, Link } from '@hbf/dsl/legacy';

const useStyles = makeStyles()(theme => ({
  menuItem: {
    color: theme.palette.secondary.main,
    paddingInline: theme.spacing(2.5),

    '&&:hover': {
      backgroundColor: theme.palette.mono.light,
    },

    '&&.Mui-selected': {
      backgroundColor: theme.palette.mono.light,
      fontWeight: 700,
      span: {
        fontWeight: 700,
      },
    },
  },
}));

interface Props extends MenuItemProps {
  to?: string;
  rel?: string;
}

const MenuItem = React.forwardRef(
  ({ children, to, ...props }: Props, ref: React.RefObject<HTMLLIElement>) => {
    const { classes, cx } = useStyles();

    const { pathname } = useLocation();

    const componentProps = to
      ? {
          to,
          component: Link,
          kind: 'dark-blue',
          disableOutline: true,
          selected: props.selected ?? to === pathname,
        }
      : undefined;

    return (
      <MenuItemMUI
        {...props}
        {...componentProps}
        ref={ref}
        className={cx(classes.menuItem, props.className)}
      >
        {children}
      </MenuItemMUI>
    );
  },
);

export { MenuItem };
