import React from 'react';

import { useMediaQuery, useTheme } from '@mui/material';

import { makeStyles } from 'tss-react/mui';

import { LanguageSwitcher } from 'ha/modules/LanguageSwitcher/components/LanguageSwitcher';
import { LoggedOutQuickActionsDrawer } from '../LoggedOutQuickActionsDrawer/LoggedOutQuickActionsDrawer';
import { LoggedOutQuickActionsMore } from '../LoggedOutQuickActionsMore/LoggedOutQuickActionsMore';

import { HeaderQuickActions } from '../HeaderQuickActions/HeaderQuickActions';

const useStyles = makeStyles()(theme => ({
  container: {
    display: 'flex',
    gap: theme.spacing(6),
    alignItems: 'center',
    justifyContent: 'space-between',
    flexWrap: 'nowrap',
  },
}));
export const LoggedOutQuickActionsMinimized = () => {
  const { classes } = useStyles();

  const theme = useTheme();
  const isLargerThanMd = useMediaQuery(theme.breakpoints.up('md'));

  const [isDrawerOpen, setIsDrawerOpen] = React.useState(false);

  if (isLargerThanMd && isDrawerOpen) {
    setIsDrawerOpen(false);
  }

  return (
    <div className={classes.container}>
      {isLargerThanMd && (
        <HeaderQuickActions>
          <LoggedOutQuickActionsMore />
          <LanguageSwitcher size="icon" />
        </HeaderQuickActions>
      )}

      {!isLargerThanMd && <LoggedOutQuickActionsDrawer />}
    </div>
  );
};

// eslint-disable-next-line import/no-default-export
export default LoggedOutQuickActionsMinimized;
