import React from 'react';
import { makeStyles } from 'tss-react/mui';

import { NavigationBottom } from 'ha/modules/Navigation/NavigationBottom';
import { RebrandThemeProvider } from 'ha/modules/ThemeProvider/RebrandThemeProvider';

const useStyles = makeStyles()(theme => ({
  fixedFooter: {
    width: '100%',
    zIndex: 1020,
    bottom: 0,
    position: 'sticky',
    background: theme.palette.common.white,
  },
}));

interface OwnProps {
  fixedFooterContent?: React.ReactNode;
  isAuthenticated: boolean;
  showBottomNav?: boolean;
}

export const FixedFooterSection: React.FC<OwnProps> = ({
  fixedFooterContent,
  isAuthenticated,
  showBottomNav,
}) => {
  const {
    classes: { fixedFooter },
  } = useStyles();

  if (fixedFooterContent) {
    return <div className={fixedFooter}>{fixedFooterContent}</div>;
  }

  return (
    <RebrandThemeProvider>
      {showBottomNav && isAuthenticated && (
        <NavigationBottom>
          <NavigationBottom.NavigationBar />
        </NavigationBottom>
      )}
    </RebrandThemeProvider>
  );
};
