/* eslint-disable custom/no-restricted-translation-jsx-untranslated-children-text */
import React from 'react';

import { useSelector } from 'react-redux';

import { makeStyles } from 'tss-react/mui';

import { Link } from '@hbf/dsl/core';

import { useImpersonationInformation } from 'ha/modules/AuthLogic/containers/Impersonation';
import { getUserFullName } from 'ha/modules/AuthLogic/selectors';

const useStyles = makeStyles()(theme => ({
  container: {
    position: 'sticky',
    top: 0,
    zIndex: 1000,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.neutral[100],
    paddingBlock: theme.utils.spacing('ref/spacing/3'),
    paddingInline: theme.utils.spacing('ref/spacing/5'),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: theme.utils.spacing('ref/spacing/5'),
  },
  link: {
    '&&': {
      color: theme.palette.neutral[100],
    },
  },
}));

export const AdminImpersonationBanner = () => {
  const { classes } = useStyles();

  const currentUserFullName = useSelector(getUserFullName);
  const { impersonatingAdmin, onStop } = useImpersonationInformation();

  if (!impersonatingAdmin) return null;

  return (
    <div className={classes.container}>
      <span>
        {impersonatingAdmin.name} ({impersonatingAdmin.id}), you are
        impersonating {currentUserFullName}
      </span>

      <Link className={classes.link} size="sm" onClick={onStop}>
        Stop
      </Link>
    </div>
  );
};
