import useCSSMediaQuery from '@mui/material/useMediaQuery';
import { useSelector } from 'react-redux';

import { getMediaQueryBreakpoints } from 'ha/modules/UI/selectors';

export interface MediaQueryBreakpoints {
  xs: boolean;
  sm: boolean;
  md: boolean;
  lg: boolean;
  xl: boolean;
}

/**
 * @deprecated Within **redesigned** components use `useMediaQuery(theme.breakpoints.up("lg"))` from MUI instead.
 * The helper relies on Redux, whereas the MUI hook is more efficient with theme and SSR.
 */
export function useMediaQuery(): MediaQueryBreakpoints {
  return useSelector(getMediaQueryBreakpoints);
}

export function useOrientation(): 'portrait' | 'landscape' {
  const isPortrait = useCSSMediaQuery('(orientation: portrait)');

  return isPortrait ? 'portrait' : 'landscape';
}
