import React from 'react';
import { useIntl } from 'ha/i18n';
import {
  ExtendProps,
  ExtendedComponent,
  ExtendedExternalLinkProps,
} from '../ExtendedComponent/types';

const OnboardingContactUsLabel = () => {
  const { T } = useIntl();

  return T('nav.footer.contact');
};

const OnboardingContactUsLink = React.forwardRef(
  <CustomComponent extends ExtendedComponent<ExtendedExternalLinkProps>>(
    { component, children, ...rest }: ExtendProps<CustomComponent>,
    ref: React.ForwardedRef<React.ComponentRef<CustomComponent>>,
  ) => {
    const { urlResolver } = useIntl();

    const Component = component as ExtendedComponent<ExtendedExternalLinkProps>;

    return (
      <Component
        ref={ref}
        {...rest}
        component="a"
        href={urlResolver.getContactUrl()}
      >
        {children ?? <OnboardingContactUsLabel />}
      </Component>
    );
  },
);

export const OnboardingContactUs = {
  Link: OnboardingContactUsLink,
  Label: OnboardingContactUsLabel,
};
