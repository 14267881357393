import React from 'react';
import { useSelector } from 'react-redux';
import { useFeatureFlags, isEnabled } from 'ha/modules/FeatureFlags';
import { usePage } from 'ha/modules/Page/contexts/PageContext';
import { getIsAuthenticated } from 'ha/modules/AuthLogic/selectors';

import { LISTING_DETAIL_PAGE, HOME, SEARCH } from 'ha/constants/pageNames';

import { GoogleOneTap } from './GoogleOneTap';

export const GoogleOneTapContainer = () => {
  const isAuthenticated = Boolean(useSelector(getIsAuthenticated));

  const { googleOneTap } = useFeatureFlags();

  const { category: pageCategory } = usePage();

  const isAllowed = [HOME, SEARCH, LISTING_DETAIL_PAGE].includes(
    pageCategory || '',
  );

  if (!isEnabled(googleOneTap) || !isAllowed || isAuthenticated) {
    return null;
  }

  return <GoogleOneTap />;
};
