import React from 'react';

import { AuthPageQueryParams, AuthPageTarget } from 'ha/helpers/UrlResolver';
import { useIntl } from 'ha/i18n';
import { extendForwardedRef } from 'ha/utils/extendForwardedRef';

import { usePage } from 'ha/modules/Page/contexts/PageContext';

import {
  ExtendedClickableProps,
  ExtendedComponent,
  ExtendedExternalLinkProps,
  ExtendProps,
} from '../ExtendedComponent/types';

const AuthButtonLoginLabel = () => {
  const { T } = useIntl();

  return T('nav.header.log_in');
};

const AuthButtonLoginForwarded = React.forwardRef(
  <
    CustomComponent extends ExtendedComponent<
      ExtendedClickableProps | ExtendedExternalLinkProps
    >,
  >(
    {
      component,
      children = <AuthButtonLoginLabel />,
      ...rest
    }: ExtendProps<CustomComponent>,
    ref: React.ForwardedRef<React.ComponentRef<CustomComponent>>,
  ) => {
    const { urlResolver } = useIntl();

    const { authLinkInfo } = usePage();
    const { continueURL, queryParams } = authLinkInfo;

    const signInUrlQueryParams = React.useMemo(() => {
      const params: AuthPageQueryParams = {
        target: queryParams?.target || AuthPageTarget.Tenant,
      };

      return params;
    }, [queryParams?.target]);

    const signInUrl = urlResolver.getOAuthSigninUrl(
      continueURL,
      signInUrlQueryParams,
    );

    const Component = component as ExtendedComponent<
      ExtendedClickableProps | ExtendedExternalLinkProps
    >;

    return (
      <Component
        ref={ref}
        component="a"
        href={signInUrl}
        {...rest}
        data-test-locator="Navigation/LoginButton"
      >
        {children}
      </Component>
    );
  },
);

export const AuthButtonLogin = extendForwardedRef(AuthButtonLoginForwarded, {
  Label: AuthButtonLoginLabel,
});
