import { GlobalState } from 'ha/types/store';

import { STATE_NAME } from '../constants';

export const getInboxUnreadCount = (s: GlobalState) =>
  s[STATE_NAME].counters.inboxUnread;

export const getInboxCounts = (s: GlobalState) => s[STATE_NAME].counters;

export const getIsLoaded = (s: GlobalState) => s[STATE_NAME].isLoaded;
